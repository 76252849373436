import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { GlobalContextProvider } from "./contexts/GlobalContext";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import NewTicket from "./components/NewTicket";
import Tickets from "./components/Tickets";
import TicketProgress from "./components/TicketProgress";
import NewRelease from "./components/NewRelease";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <GlobalContextProvider>
      <Router>
        <Routes>
          {/* <Route path="/" element={<App />}></Route> */}
          <Route path="/" element={<Login />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route
            path="/:projectId/new-release"
            element={<NewRelease />}
          ></Route>
          <Route
            path="/:projectId/raise-ticket"
            element={<NewTicket />}
          ></Route>
          <Route path="/:projectId/tickets" element={<Tickets />}></Route>
          <Route
            path="/progress/:projectId/:ticketId"
            element={<TicketProgress />}
          ></Route>
          <Route path="/*" element={<Navigate to="/" />}></Route>
        </Routes>
      </Router>
    </GlobalContextProvider>
  </React.StrictMode>
);
