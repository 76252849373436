import { useParams } from "react-router-dom";
import Header from "./Header";
import { useEffect, useState } from "react";
import Ticket from "../classes/Ticket";
import { Link } from "react-router-dom";
import {
  getTicketDetails,
  getTicketUpdates,
} from "../services/tickets-services";
import { formatTimestamp } from "../services/DateUtils";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import TicketUpdates from "./TicketUpdates";
import TicketUpdate from "../classes/TicketUpdate";

let isLoading: boolean = true;
let isUpdatesLoading: boolean = false;

const RELEASE_DESC_CHAR_LIMIT = 150;

// Function to check if the URL is a video
const isVideo = (url: string) => {
  return /\.(mp4|ogg|webm)$/i.test(url);
};

const testTicketUpdates: TicketUpdate[] = [
  {
    id: "string",
    title: "Fixed it in v19.5.6",
    description: "this is description of the update",
    created_at: 1700557081344,
    updated_by: "RiyazAhamad",
    avatar: "",
  },
  {
    id: "string",
    title: "Recived the update and started working on it",
    description: "this is description of the update",
    created_at: 1700557011344,
    updated_by: "RiyazAhamad",
    avatar: "https://avatars.githubusercontent.com/u/55573156?v=4",
  },
];

function TicketProgress() {
  const { projectId, ticketId } = useParams();

  let [ticket, setTicket] = useState<Ticket>();
  // let [ticketUpdates, setTicketUpdates] = useState<TicketUpdate[]>(testTicketUpdates);
  let [ticketUpdates, setTicketUpdates] = useState<TicketUpdate[]>([]);

  // const projectId = "allhorses";

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("fetching ticket data from fb for: " + projectId);
        if (projectId && ticketId) {
          // fetch single ticket from firestore
          const ticketsFromFB = await getTicketDetails(projectId, ticketId);
          const data = ticketsFromFB?.data() as Ticket;
          if (data) {
            console.log("latest ticket data form fb: ", data);
            isLoading = false;
            isUpdatesLoading = true;
            setTicket(data);
          }

          // fetch that ticket updates from firestore
          const ticketUpdates = await getTicketUpdates(projectId, ticketId);
          const updatesData = ticketUpdates?.docs.map(
            (doc) => doc.data() as TicketUpdate
          );
          if (updatesData) {
            console.log("ticket updates form fb: ", updatesData);
            isUpdatesLoading = false;
            setTicketUpdates(updatesData);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  let [viewFullDesc, setViewFullDesc] = useState(false);

  const getReleaseDesc = (originalDesc: string) => {
    let limtedDesc = "";
    if (originalDesc.length >= RELEASE_DESC_CHAR_LIMIT) {
      limtedDesc = originalDesc.slice(0, RELEASE_DESC_CHAR_LIMIT) + " ...";
    }

    return limtedDesc ?? originalDesc;
  };

  const viewFullDescClickHandler = () => {
    setViewFullDesc(true);
  };

  const getTicketNum = (id: string) => {
    // 1699867 628931
    return id.slice(7, id.length);
  };

  const getStatusBadgeCSSClass = (status: string) => {
    switch (status) {
      case "open":
        return "ticket-status-badge yellow-bg";

      case "fixed":
      case "closed":
        return "ticket-status-badge green-bg";

      case "progress":
        return "ticket-status-badge red-bg";

      default:
        return "ticket-status-badge yellow-bg";
    }
  };

  const handleCopyClick = (ticketNumber: string) => {
    if (navigator.clipboard) {
      // If the clipboard API is supported, use it
      navigator.clipboard
        .writeText(ticketNumber)
        .then(() => {
          alert("Ticket ID copied to clipboard!");
        })
        .catch((error) => {
          console.error("Copy failed:", error);
        });
    } else {
      // Fallback method for unsupported browsers
      const textArea = document.createElement("textarea");
      textArea.value = ticketNumber;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      alert("Ticket ID copied to clipboard!");
    }
  };

  return (
    <>
      <div className="mainFrame">
        <Header />
        <div className="card">
          <div className="title-contianer">
            <div className="card-title-contianer pt-1">
              <img src="/icons/bugs.svg" alt="" />
              <h1 className="card-title pb-1">Ticket</h1>
            </div>
            <Link className="button" to={"/" + projectId + "/raise-ticket/"}>
              <img className="button-icon" src="/icons/add_button.svg" alt="" />{" "}
              New Tickets
            </Link>
          </div>

          {isLoading ? (
            <img
              className="loading-anim"
              src="/icons/loading-anim.gif"
              alt=""
            />
          ) : (
            <></>
          )}

          <div className="secondary-card-container">
            {ticket?.id ? (
              <div className="secondary-card">
                <div className="flexCol-nc ticket-article-header-wmt ">
                  <div className="flexCol-nc article-title-container">
                    <h1 className="article-title">{ticket.title}</h1>
                    <p className="time">{formatTimestamp(ticket.created_at)}</p>
                    {/* <p className="priority-badge">{ticket.priority}</p> */}
                  </div>
                  {
                    <p
                      className={getStatusBadgeCSSClass(ticket.status)}
                      onClick={() => handleCopyClick(getTicketNum(ticket!.id))}
                    >
                      {" "}
                      <span className="flexRow ticket-number">
                        #{getTicketNum(ticket.id)}{" "}
                        <img
                          className="copy-icon"
                          src="/icons/copy.svg"
                          alt=""
                        />
                      </span>{" "}
                      {ticket.status}
                    </p>
                  }
                </div>

                <div className="article-body">
                  <div className="article-section">
                    <h3 className="section-title">Details:</h3>
                    <div className="release-desc-container">
                      {viewFullDesc ? (
                        <>
                          <p className="section-body">{ticket.description}</p>
                        </>
                      ) : (
                        <p className="section-body">
                          {getReleaseDesc(ticket.description)}
                        </p>
                      )}

                      {!viewFullDesc ? (
                        <p
                          className="flexCol more-info"
                          onClick={() => viewFullDescClickHandler()}
                        >
                          {viewFullDesc ? "" : "view full details ..."}
                        </p>
                      ) : (
                        <></>
                      )}
                      <div className="article-section">
                        <h3 className="section-title">Platform:</h3>
                        <div className="release-desc-container">
                          <p className="section-body">{ticket.platform}</p>
                        </div>
                      </div>

                      <div className="article-section">
                        <h3 className="section-title">Screenshots/videos:</h3>
                        <div className="release-desc-container">
                          {ticket?.media?.length > 0 && (
                            <div
                              className={
                                ticket.media.length > 1
                                  ? "media-container scroll-x"
                                  : "media-container"
                              }
                            >
                              {ticket?.media?.map((mediaUrl, index) => (
                                <div key={index} className="media-container">
                                  {isVideo(mediaUrl) ? (
                                    <video
                                      className="ticket-media"
                                      controls
                                      autoPlay
                                      loop
                                      muted
                                    >
                                      <source src={mediaUrl} type="video/mp4" />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  ) : (
                                    <>
                                      <LazyLoadImage
                                        className="ticket-media"
                                        alt={""}
                                        effect="blur"
                                        wrapperProps={{
                                          // If you need to, you can tweak the effect transition using the wrapper style.
                                          style: {
                                            transitionDelay: "0.5s",
                                          },
                                        }}
                                        src={mediaUrl} // use normal <img> attributes as props
                                      />
                                    </>
                                  )}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          <TicketUpdates updates={ticketUpdates} />
          {isUpdatesLoading ? (
            <img
              className="loading-anim"
              src="/icons/loading-anim.gif"
              alt=""
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default TicketProgress;
