import { getDocsFromFirestoreWithSort } from "../services/firebase";

// Paths
const getLatestReleasePath = (projectId: string) => {
  return "Projects/" + projectId + "/Releases";
};

export const getLatestReleases = async (project_id: string) => {
  try {
    return await getDocsFromFirestoreWithSort(
      getLatestReleasePath(project_id),
      "created_at",
      "desc"
    );
  } catch (err) {
    // console.log("Error getting latest release note: ", err);
    throw err;
  }
};
