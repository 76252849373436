import { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../contexts/GlobalContext";
import { useNavigate } from "react-router-dom";
import NavBar from "./NavBar";
import AlertDialog from "./AlertDialog";
import { AlertDialogConfigs } from "../classes/AlertConfig";

function getGreeting(): string {
  const currentHour = new Date().getHours();

  if (currentHour >= 5 && currentHour < 12) {
    return "Good Morning";
  } else if (currentHour >= 12 && currentHour < 18) {
    return "Good Afternoon";
  } else if (currentHour >= 18 && currentHour < 21) {
    return "Good Evening";
  } else {
    return "Good Night";
  }
}

function Header() {
  const navigate = useNavigate();
  const greetingText: string = getGreeting();
  const { currentUser, getCurrentUser, logOut } = useContext(GlobalContext);

  let [thisAlertConfig, setThisAlertConfig] = useState<AlertDialogConfigs>({
    isOpen: false,
    title: "",
    message: "",
    secondaryBtnText: "",
    primaryBtnText: "",
    primaryBtnAction: logOut
  });

  


  useEffect(() => {
    if (getCurrentUser() == null) {
      navigate("/");
    }
    console.log("Current User: ", getCurrentUser());
  }, [getCurrentUser, navigate]);

  return (
    <>
      <div className="header-container">
        <div className="header">
          <div className="logo-container">
            <img className="logo" src="/logo192.png" alt="" />
            <p className="logo-title">Maintenance Records</p>
          </div>

          <div className="greatings-container">
            <h1 className="greeting-text">{greetingText},</h1>
            <h2 className="user-name">{currentUser.name}</h2>
          </div>
        </div>

        <div className="profile">
          <img
            className="profile-avatar"
            src={currentUser.profilePicUrl}
            alt=""
          />
          <p
            className="logout"
            onClick={() =>
              setThisAlertConfig({
                isOpen: true,
                title: "Are you sure to logout?",
                message: "Once logged out, you need to login in again.",
                secondaryBtnText: "No",
                primaryBtnText: "Yes",
                primaryBtnAction: logOut
              })
            }
          >
            Logout
          </p>
        </div>
      </div>

      <AlertDialog alertConfig={thisAlertConfig} />
      <NavBar></NavBar>
    </>
  );
}

export default Header;
