import { ThemeProvider } from "@emotion/react";
import {
  TextField,
  Snackbar,
  createTheme,
  AlertProps,
  Input,
} from "@mui/material";
import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import MuiAlert from "@mui/material/Alert";
import { Link, useNavigate } from "react-router-dom";
import { GlobalContext } from "../contexts/GlobalContext";
import { uploadTicketMedia, writeNewTicketToFB } from "../services/firebase";
import Header from "./Header";
import Ticket from "../classes/Ticket";
import { useParams } from "react-router-dom";

// Define your custom theme
const theme = createTheme({
  typography: {
    fontSize: 20, // Adjust the font size as needed
    fontFamily: "myFont, Arial, sans-serif", // Specify your desired font family
    fontWeightRegular: "400",
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#2f3e46", // Change the outline color
        },
        focused: {
          color: "red", // Label color when focused (red)
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& fieldset": {
            borderWidth: "0.1em", // Change the outline width
            borderRadius: "0.71em",
            borderColor: "#2f3e46",
          },
          "&:hover fieldset": {
            borderColor: "#2f3e46",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#2f3e46", // Outline color when focused (red)
          },
        },
      },
    },
  },
});

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const platforms = ["iOS", "android", "web"];

const snackBarConfigType: {
  open: boolean;
  severity: "success" | "error" | "warning" | "info";
  message: string;
} = { open: false, severity: "success", message: "" };

const envOptions = [
  { platform: "iOS" },
  { platform: "android" },
  { platform: "admin/web" },
];

function NewTicket() {
  const { projectId } = useParams();
  let [selectedEnvTag, setSelectedEnvTag] = useState(0);

  const [snackBarConfig, setSnackBarConfig] = useState(snackBarConfigType);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarConfig({ open: false, severity: "success", message: "" });
  };

  //   UI Configeration done ✅

  //   Logic starts from here 👇

  const PROJECT_ID = projectId ?? "anonymous";

  const { currentUser, getCurrentUser } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser.id === "") {
      navigate("/login");
    }
  }, [currentUser.id, navigate]);

  const [sucessfullyRaisedTicket, setSucessfullyRaisedTicket] = useState(false);

  // Ticket data
  const [ticketTitle, setTicketTitle] = useState("");
  const [ticketDesc, setTicketDesc] = useState("");
  const [ticketPlatform, setTicketPlatform] = useState(platforms[0]);
  const [customerPlace, setCustomerPlace] = useState("");

  const [mediaFiles, setMediaFiles] = useState<File[]>([]);
  const [downloadUrls, setDownloadUrls] = useState<string[]>([]);

  const clearTicketForm = () => {
    setTicketTitle("");
    setTicketDesc("");
    setTicketPlatform("");
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const fileListArray = Array.from(files);
      // Limit to a maximum of 5 files
      if (mediaFiles.length + fileListArray.length <= 5) {
        setMediaFiles([...mediaFiles, ...fileListArray]);
      } else {
        // You can handle exceeding the limit (e.g., show a message)
        console.error("Maximum of 5 files allowed.");
      }
    }
  };

  const handleTicketSave = () => {
    console.log("Save button clicked");
    if (!isLoading && ticketTitle && ticketDesc && ticketPlatform) {
      const id = Date.now();

      const newTicketData: Ticket = {
        id: id.toString(),
        title: ticketTitle,
        description: ticketDesc,
        media: [],
        platform: ticketPlatform,
        priority: 0,
        status: "open",

        created_at: id,
        raised_by: currentUser.id,
      };
      console.log("New Ticket Data: ", newTicketData);

      setIsLoading(true);

      uploadTicketMedia(currentUser.id, mediaFiles)
        .then((downloadableUrls) => {
          // Use the downloadableUrls array
          console.log(downloadableUrls);

          if (downloadableUrls) setDownloadUrls(downloadableUrls);
          if (mediaFiles.length === downloadableUrls?.length) {
            newTicketData.media = downloadableUrls;
            // /*
            writeNewTicketToFB(PROJECT_ID, newTicketData.id, newTicketData)
              .then((res) => {
                console.log("✅ Ticket Data Saved");
                setSnackBarConfig({
                  open: true,
                  severity: "success",
                  message: "Ticket Details Saved",
                });
              })
              .catch((err) => {
                setSnackBarConfig({
                  open: true,
                  severity: "error",
                  message: "Error while saving Ticket data" + err,
                });
                console.log("Error while saving Ticket data: ", err);
              })
              .finally(() => {
                setIsLoading(false);
                clearTicketForm();
                setSucessfullyRaisedTicket(true);
              });
            // */
          }
        })
        .catch((error) => {
          // Handle errors
          console.error("Error:", error);
        });
    }
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleSelectButtonClick = () => {
    // Trigger file input click when the select button is clicked
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const envOptionClickHandler = (envIndex: number) => {
    const platform = platforms[envIndex];
    setTicketPlatform(platform);
    setSelectedEnvTag(envIndex);
  };

  return (
    <>
      <div className="mainFrame">
        <Header />

        <div className="card">
          <div className="card-title-contianer">
            <img src="/icons/bugs.svg" alt="" />
            <h1 className="card-title pb-1">Report Bug/Ticket</h1>
          </div>

          <div className="secondary-card">
            {sucessfullyRaisedTicket ? (
              <div className="success-message">
                <img
                  className="message-icon"
                  src="/icons/check-circle-outline.svg"
                  alt=""
                />
                <h1 className="message-title">That’s a Success!</h1>
                <p className="message-body">
                  We acknowledge the receipt of your request, and our dedicated
                  team is committed to promptly resolving it. You can expect us
                  to reach out to you shortly to gather any additional
                  information required for a swift resolution.
                </p>

                <div className="flexRow button-containers article-section">
                  <Link className="button" to={"/" + projectId + "/tickets"}>
                    <img
                      className="button-icon"
                      src="/icons/exit-to-app.svg"
                      alt=""
                    />{" "}
                    Goto Tickets
                  </Link>
                </div>
              </div>
            ) : (
              <>
                <ThemeProvider theme={theme}>
                  <div className="form-input-el">
                    <TextField
                      className="form-input-el"
                      fullWidth
                      id="outlined-basic"
                      label="Enter title ..."
                      variant="outlined"
                      value={ticketTitle}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setTicketTitle(event.target.value);
                      }}
                    />
                  </div>
                  <div className="form-input-el">
                    <TextField
                      className="form-input-desc"
                      fullWidth
                      multiline
                      rows={3}
                      id="outlined-basic"
                      label="Describe the bug/concern ..."
                      variant="outlined"
                      value={ticketDesc}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setTicketDesc(event.target.value);
                      }}
                    />
                  </div>
                  <div className="form-input">
                    <input
                      className="hidden"
                      id=""
                      multiple
                      type="file"
                      ref={fileInputRef}
                      value={customerPlace}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        handleFileChange(event);
                      }}
                    />

                    <div
                      className="flexRow form-input-element"
                      onClick={handleSelectButtonClick}
                    >
                      <img className="file-icon" src="/icons/file.svg" alt="" />
                      <p className="form-input-label">
                        Attach screenshots/videos
                      </p>
                    </div>
                  </div>
                  <div className="media-result">
                    {/* Display selected media */}
                    {mediaFiles.map((file, index) => (
                      <div key={index}>
                        {"✨" + (index + 1) + ": " + file.name}
                      </div>
                    ))}

                    {/* Display download URLs 
                  {downloadUrls.map((url, index) => (
                    <div key={index}>{url}</div>
                  ))}
                */}
                  </div>

                  <div className="form-input-el">
                    <p className="form-label">Select platform</p>
                    <div className="env-container">
                      {platforms.map((option, index) => (
                        <div
                          key={index}
                          className={
                            index === selectedEnvTag
                              ? "platform-option-tag selected-tag"
                              : "platform-option-tag"
                          }
                          onClick={() => envOptionClickHandler(index)}
                        >
                          {option}
                        </div>
                      ))}
                    </div>
                  </div>
                </ThemeProvider>

                <button
                  id="contactFormSubmitBtn"
                  className={
                    isLoading ? "primary-button disabled" : "primary-button"
                  }
                  type="submit"
                  onClick={handleTicketSave}
                >
                  {isLoading ? (
                    <CircularProgress
                      className="button-icons"
                      style={{ color: "white" }}
                      size={30}
                    />
                  ) : (
                    <img
                      className="button-icons"
                      src="/icons/description.svg"
                      alt=""
                    />
                  )}
                  <p className="button-text-regular">SAVE</p>
                </button>
              </>
            )}
          </div>
        </div>

        <Snackbar
          open={snackBarConfig.open}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert severity={snackBarConfig.severity} sx={{ width: "80vw" }}>
            {snackBarConfig.message}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}

export default NewTicket;
