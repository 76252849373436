import {
  getDocsFromFirestoreWithSort,
  getSignleDocFromFirestore,
} from "./firebase";

const getLatestTicketsPath = (projectId: string) => {
  return "Projects/" + projectId + "/Tickets";
};

export const getLatestTickets = async (project_id: string) => {
  try {
    return await getDocsFromFirestoreWithSort(
      getLatestTicketsPath(project_id),
      "created_at",
      "desc"
    );
  } catch (err) {
    console.log("Error getting latest release note: ", err);
    throw err
  }
};

export const getTicketDetails = async (
  project_id: string,
  ticketId: string
) => {
  try {
    return await getSignleDocFromFirestore(
      getLatestTicketsPath(project_id) + "/" + ticketId
    );
  } catch (err) {
    console.log("Error getting latest release note: ", err);
    throw err
  }
};

export const getTicketUpdates = async (
  project_id: string,
  ticketId: string
) => {
  try {
    return await getDocsFromFirestoreWithSort(
      getLatestTicketsPath(project_id) + "/" + ticketId + "/Updates",
      "created_at",
      "desc"
    );
  } catch (err) {
    console.log("Error getting latest release note: ", err);
    throw err
  }
};
