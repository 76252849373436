import { useNavigate } from "react-router-dom";
import { useRef, useState, useContext, useEffect, forwardRef } from "react";
import { GlobalContext } from "../contexts/GlobalContext";
import MuiAlert from "@mui/material/Alert";
import {
  TextField,
  Snackbar,
  createTheme,
  AlertProps,
  Input,
} from "@mui/material";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const snackBarConfigType: {
  open: boolean;
  severity: "success" | "error" | "warning" | "info";
  message: string;
} = { open: false, severity: "success", message: "" };

function Login() {
  const navigate = useNavigate();
  const [snackBarConfig, setSnackBarConfig] = useState(snackBarConfigType);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarConfig({ open: false, severity: "success", message: "" });
  };

  const { logIn, logOut, currentUser, getCurrentUser } =
    useContext(GlobalContext);

  const loginForm: React.RefObject<HTMLFormElement> = useRef(null);
  const loginId: React.RefObject<HTMLInputElement> = useRef(null);
  const password: React.RefObject<HTMLInputElement> = useRef(null);

  const [isValidId, setIdValid] = useState(true);
  const [isValidPassword, setPasswordValid] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    if (getCurrentUser() !== null) {
      navigate("/dashboard");
    }
    console.log("Current User: ", getCurrentUser());
  }, [getCurrentUser, navigate]);

  const handleIdInputField = () => {
    if (
      loginId.current?.value !== undefined &&
      loginId.current?.value !== null &&
      loginId.current?.value.length >= 3
    ) {
      setIdValid(true);
      // return true;
    } else {
      console.log("ID: ", loginId.current?.value);
      setIdValid(false);
      // return false;
    }
  };

  const handlePasswordInputField = (): boolean => {
    if (
      password.current?.value !== undefined &&
      password.current?.value !== null &&
      password.current?.value.length >= 3
    ) {
      setPasswordValid(true);
      return true;
    } else {
      setPasswordValid(false);
      return false;
    }
  };

  const isValidForm = (): boolean => {
    // let isValid: boolean = false;

    // if (isValidId) {
    //   isValid = true;
    // }

    return isValidId;
  };

  const getFormData = () => {
    const m_id = loginId.current?.value ?? "";
    const m_password = password.current?.value ?? "";

    loginWithFB(m_id, m_password);
  };

  async function loginWithFB(m_id: string, m_password: string) {
    try {
      setError("");

      await logIn(m_id, m_password)
        // await logIn(m_id + "@theriyazo.com", m_password)
        .then((user) => {
          setSnackBarConfig({
            open: true,
            severity: "success",
            message: "Welcome back!",
          });
          setTimeout(() => {
            navigate("/dashboard");
          }, 1000);
        })
        .catch((err) => {
          setError(err);
          setSnackBarConfig({
            open: true,
            severity: "error",
            message: "Error while siging in" + err,
          });
          console.log("Error while siging in: ", err);
        });
    } catch (err) {
      const errString = `${err}`;
      setError(errString);
      setSnackBarConfig({
        open: true,
        severity: "error",
        message: "Error while siging in" + errString,
      });
      console.log(error);
    }

    // if(m_id === "7204987524" && m_password === "ryz123"){
    //     updateCurrentUser(currentTestUser);
    //     navigate('/dashboard');
    // } else {
    //     alert("Wrong creadentials!")
    // }
  }

  const handleLogin = () => {
    if (isValidForm()) {
      getFormData();
    }
  };

  return (
    <>
      <div className="mainFrame">
        <h1 className="pageTitle-normal">Welcome to Meldix!</h1>

        <div className="loginFormContainer">
          <form
            ref={loginForm}
            id="loginForm"
            action=""
            onSubmit={(_e: any) => {
              _e.preventDefault();
            }}
          >
            <div className="form-row">
              <p className="form-element">
                <label htmlFor="email"></label>
                <input
                  className={
                    isValidId
                      ? "form-input-element"
                      : "form-input-element form-field-error"
                  }
                  maxLength={250}
                  // type="tel"
                  name="email"
                  placeholder="enter your email"
                  id="email"
                  ref={loginId}
                  onChange={handleIdInputField}
                  required
                />
              </p>
            </div>

            <div className="form-row">
              <p className="form-element">
                <label htmlFor="phone"></label>
                <input
                  className={
                    isValidPassword
                      ? "form-input-element"
                      : "form-input-element form-field-error"
                  }
                  maxLength={18}
                  type="password"
                  name="password"
                  placeholder="enter password"
                  id="password"
                  ref={password}
                  onChange={handlePasswordInputField}
                  required
                />
              </p>
            </div>
          </form>
        </div>

        <button
          id="contactFormSubmitBtn"
          className="form-row primaryButton-login"
          type="submit"
          onClick={handleLogin}
        >
          <p className="loginButtonText">Login</p>
        </button>
      </div>
      <Snackbar
        open={snackBarConfig.open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert severity={snackBarConfig.severity} sx={{ width: "80vw" }}>
          {snackBarConfig.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Login;
