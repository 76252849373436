import { useState, useEffect, forwardRef } from "react";
import MuiAlert from "@mui/material/Alert";
import { Snackbar, AlertProps } from "@mui/material";

interface CustomSnackbarProps {
  snackbarConfig: typeof snackBarConfigType;
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const snackBarConfigType: {
  open: boolean;
  severity: "success" | "error" | "warning" | "info";
  message: string;
} = { open: false, severity: "success", message: "" };

function CustomSnackbar(props: CustomSnackbarProps) {
  const { snackbarConfig } = props;
  const [snackBarConfig, setSnackBarConfig] = useState(snackBarConfigType);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarConfig({
      open: false,
      severity: snackbarConfig.severity,
      message: snackbarConfig.message,
    });
  };

  useEffect(() => {
    setSnackBarConfig(snackbarConfig);
  }, [snackbarConfig]);

  return (
    <>
      <Snackbar
        open={snackBarConfig.open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert severity={snackBarConfig.severity} sx={{ width: "80vw" }}>
          {snackBarConfig.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default CustomSnackbar;
