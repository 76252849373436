import { Link } from "react-router-dom";

function NavBar() {
  return (
    <>
      <div className="floating-button-container floating-button-container-right">
        <Link to="/allhorses-prod/tickets">
        {/* <Link to="/raise-ticket/allhorses"> */}
          <div className="floating-button-icon">
            <img
              className="nav-icon"
              src="/icons/bug-icon.svg"
              alt=""
            />
          </div>
        </Link>
      </div>

      <div className="floating-button-container floating-button-container-left">
        <Link to="/dashboard">
          <div className="floating-button-icon">
            <img className="nav-icon" src="/icons/home_button.svg" alt="" />
          </div>
        </Link>
      </div>
    </>
  );
}

export default NavBar;
