import Header from "./Header";
import { useEffect, useState } from "react";
import Release from "../classes/Release";
import { getLatestReleases } from "../services/latest-releases";
import Ticket from "../classes/Ticket";
import { Link } from "react-router-dom";
import { getLatestTickets } from "../services/tickets-services";
import { formatTimestamp } from "../services/DateUtils";
import { useParams } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import CustomSnackbar from "./CustomSnackbar";
import { snackBarConfigType } from "../classes/SnackBarConfig";

let isLoading: boolean = true;

const RELEASE_DESC_CHAR_LIMIT = 150;
const envOptions = [
  { platform: "iOS" },
  { platform: "android" },
  { platform: "admin/web" },
];

// Function to check if the URL is a video
const isVideo = (url: string) => {
  const lowerCaseUrl = url.toLowerCase();
  return /\.(mp4|ogg|webm)(\?.*)?$/i.test(lowerCaseUrl);
};

function Tickets() {
  const { projectId } = useParams();
  let [snackBarConfig, setSnackBarConfig] = useState<snackBarConfigType>({
    open: false,
    severity: "success",
    message: "",
  });

  let [releases, setReleases] = useState<Ticket[]>([]);
  let [filteredReleases, setFilteredReleases] = useState<Ticket[]>([]);

  const [selectedPlatform, setSelectedPlatform] = useState<string>("ios");

  // const projectId = "allhorses";

  useEffect(() => {
    const fetchData = async () => {
      setReleases([]);
      try {
        console.log("fetching latest release data from fb for: " + projectId);
        if (projectId) {
          const ticketsFromFB = await getLatestTickets(projectId);
          const data = ticketsFromFB?.docs.map((doc) => doc.data() as Ticket);
          if (data) {
            console.log("latest release data form fb: ", data);
            isLoading = false;
            setReleases(data);
            envOptionClickHandler(0, data);
          }
        }
      } catch (error) {
        console.log("Error fetching data:", error);
        setSnackBarConfig({
          open: true,
          severity: "error",
          message: "Error fetching data: " + error,
        });
      }
    };

    fetchData();
  }, []);

  let [selectedEnvTag, setSelectedEnvTag] = useState(0);
  let [viewFullDesc, setViewFullDesc] = useState("");

  const envOptionClickHandler = (
    envIndex: number,
    releaseDataArray?: Ticket[]
  ) => {
    const platforms = ["iOS", "android", "web"];
    setSelectedEnvTag(envIndex);

    let originalData: Ticket[] = [];

    if (envIndex >= 0 && envIndex < platforms.length) {
      const selectedPlatform = platforms[envIndex];
      setSelectedPlatform(selectedPlatform);
      console.log(`${selectedPlatform} selected`);

      if (releaseDataArray) {
        originalData = releaseDataArray;
      } else {
        originalData = releases;
      }

      const filteredReleases = originalData.filter(
        (ticket) => ticket.platform === selectedPlatform
      );

      console.log("filtered releases: ", filteredReleases);
      setFilteredReleases(filteredReleases);
      console.log("selected platform: ", selectedPlatform);
    }
  };

  const getReleaseDesc = (originalDesc: string) => {
    let limtedDesc = "";
    if (originalDesc.length >= RELEASE_DESC_CHAR_LIMIT) {
      limtedDesc = originalDesc.slice(0, RELEASE_DESC_CHAR_LIMIT) + " ...";
    }

    return limtedDesc ?? originalDesc;
  };

  const viewFullDescClickHandler = (index: number) => {
    setViewFullDesc(viewFullDesc + "" + index);
  };
  const getTicketNum = (id: string) => {
    // 1699867 628931
    return id.slice(7, id.length);
  };

  const getStatusBadgeCSSClass = (status: string) => {
    switch (status) {
      case "open":
        return "ticket-status-badge yellow-bg";

      case "fixed":
      case "closed":
        return "ticket-status-badge green-bg";

      case "progress":
        return "ticket-status-badge red-bg";

      default:
        return "ticket-status-badge yellow-bg";
    }
  };

  const handleCopyClick = (ticketNumber: string) => {
    if (navigator.clipboard) {
      // If the clipboard API is supported, use it
      navigator.clipboard
        .writeText(ticketNumber)
        .then(() => {
          alert("Ticket ID copied to clipboard!");
        })
        .catch((error) => {
          console.error("Copy failed:", error);
        });
    } else {
      // Fallback method for unsupported browsers
      const textArea = document.createElement("textarea");
      textArea.value = ticketNumber;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      alert("Ticket ID copied to clipboard!");
    }
  };

  return (
    <>
      <div className="mainFrame">
        <Header />
        <CustomSnackbar snackbarConfig={snackBarConfig} />

        <div className="card">
          <div className="title-contianer">
            <div className="card-title-contianer">
              <img src="/icons/bugs.svg" alt="" />
              <h1 className="card-title pb-1">Tickets</h1>
            </div>
            <Link className="button" to={"/" + projectId + "/raise-ticket/"}>
              <img className="button-icon" src="/icons/add_button.svg" alt="" />{" "}
              New Tickets
            </Link>
          </div>

          <div className="env-container">
            {envOptions.map((option, index) => (
              <div
                key={index}
                className={
                  index === selectedEnvTag
                    ? "option-tag selected-tag"
                    : "option-tag"
                }
                onClick={() => envOptionClickHandler(index)}
              >
                {option.platform}
              </div>
            ))}
          </div>

          {isLoading ? (
            <img
              className="loading-anim"
              src="/icons/loading-anim.gif"
              alt=""
            />
          ) : (
            <></>
          )}

          <div className="secondary-card-container">
            {filteredReleases.map((ticket, index) => (
              <div key={index} className="secondary-card">
                <div className="flexCol-nc ticket-article-header">
                  {
                    <p
                      className={getStatusBadgeCSSClass(ticket.status)}
                      onClick={() => handleCopyClick(getTicketNum(ticket.id))}
                    >
                      {" "}
                      <span className="flexRow ticket-number">
                        #{getTicketNum(ticket.id)}{" "}
                        <img
                          className="copy-icon"
                          src="/icons/copy.svg"
                          alt=""
                        />
                      </span>{" "}
                      {ticket.status}
                    </p>
                  }

                  <div className="flexCol-nc article-title-container">
                    <h1 className="article-title">{ticket.title}</h1>
                    <p className="time">{formatTimestamp(ticket.created_at)}</p>
                    {/* <p className="priority-badge">{ticket.priority}</p> */}
                  </div>
                </div>

                <div className="article-body">
                  <div className="article-section">
                    <h3 className="section-title">Details:</h3>
                    <div className="release-desc-container">
                      {viewFullDesc.includes(index.toString()) ? (
                        <>
                          <p className="section-body">{ticket.description}</p>

                          <div className="article-section">
                            <h3 className="section-title">Platform:</h3>
                            <div className="release-desc-container">
                              <p className="section-body">{ticket.platform}</p>
                            </div>
                          </div>

                          <div className="article-section">
                            <h3 className="section-title">
                              Screenshots/videos:
                            </h3>
                            <div className="release-desc-container">
                              {ticket?.media?.length > 0 && (
                                <div
                                  className={
                                    ticket.media.length > 1
                                      ? "media-container scroll-x"
                                      : "media-container"
                                  }
                                >
                                  {ticket?.media?.map((mediaUrl, index) => (
                                    <div
                                      key={index}
                                      className="media-container"
                                    >
                                      {isVideo(mediaUrl) ? (
                                        <video
                                          className="ticket-media"
                                          controls
                                          autoPlay
                                          loop
                                          muted
                                        >
                                          <source
                                            src={mediaUrl}
                                            type="video/mp4"
                                          />
                                          Your browser does not support the
                                          video tag.
                                        </video>
                                      ) : (
                                        <>
                                          <LazyLoadImage
                                            className="ticket-media"
                                            alt={""}
                                            effect="blur"
                                            wrapperProps={{
                                              // If you need to, you can tweak the effect transition using the wrapper style.
                                              style: {
                                                transitionDelay: "0.5s",
                                              },
                                            }}
                                            src={mediaUrl} // use normal <img> attributes as props
                                          />
                                        </>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <p className="section-body">
                          {getReleaseDesc(ticket.description)}
                        </p>
                      )}

                      {!viewFullDesc.includes(index.toString()) ? (
                        <p
                          className="flexCol more-info"
                          onClick={() => viewFullDescClickHandler(index)}
                        >
                          {viewFullDesc.includes(index.toString())
                            ? ""
                            : "view full details ..."}
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  {/* <div className="article-section">
                    <h3 className="section-title">Platform:</h3>
                    <div className="release-desc-container">
                      <p className="section-body">{ticket.platform}</p>
                    </div>
                  </div>

                  <div className="article-section">
                    <h3 className="section-title">Screenshots/videos:</h3>
                    <div className="release-desc-container">
                      {ticket?.media?.length > 0 && (
                        <div
                          className={
                            ticket.media.length > 1
                              ? "media-container scroll-x"
                              : "media-container"
                          }
                        >
                          {ticket?.media?.map((mediaUrl, index) => (
                            <div key={index} className="media-container">
                              {isVideo(mediaUrl) ? (
                                <video
                                  className="ticket-media"
                                  controls
                                  autoPlay
                                  loop
                                  muted
                                >
                                  <source src={mediaUrl} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video>
                              ) : (
                                <img
                                  className="ticket-media"
                                  src={mediaUrl}
                                  alt={`media-${index}`}
                                />
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div> */}

                  <div className="article-section">
                    <Link
                      className="button"
                      to={"/progress/" + projectId + "/" + ticket.id}
                    >
                      <img
                        className="button-icon"
                        src="/icons/description.svg"
                        alt=""
                      />{" "}
                      View Progress
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Tickets;

/*
function Tickets() {
  return (
    <>
      <div className="mainFrame">
        <Header />

        <div className="card">
          <h1 className="card-title">
            <img src="/icons/bugs.svg" alt="" />Bugs/Tickets</h1>

          <div className="secondary-card">
            
          </div>
        </div>
      </div>
    </>
  );
}

export default Tickets;
*/
