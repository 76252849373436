import { UserCredential, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import React, { createContext, useEffect, useState } from 'react';
import auth, { getSignleDocFromFirestore } from '../services/firebase';
import User from '../classes/User';


const USER_COLLECTION = "Users";

type MyContextValue = {
  currentUser: User;
  logIn: (email: string, password: string) => Promise<UserCredential>;
  logOut: () => Promise<void>;
  updateCurrentUser: (newUser: User) => void;
  getCurrentUser: () => any
};

const initialContextValue: MyContextValue = {
  currentUser: { id: "", name: "", phone: 0, email: "", role: "", profilePicUrl: "" },
  updateCurrentUser: () => { },
  getCurrentUser: () => { },
  logIn: function (email: string, password: string): Promise<UserCredential> {
    return new Promise((resolve, reject) => {
      
    })
  },
  logOut: () => {
    return new Promise((resolve, reject) => {
      
    })
  },

};

export const GlobalContext = createContext(initialContextValue);

type MyContextProviderProps = {
  children: React.ReactNode;
};

const localStorageUserKey = "E7hR9kP2sLjF4tY1554G";

export const GlobalContextProvider: React.FC<MyContextProviderProps> = ({
  children,
}) => {
  const [currentUser, setCurrentUser] = useState<User>({ id: "", name: "", phone: 0,  email: "", role: "", profilePicUrl: ""});
  
  console.log("GlobalContextProvider visited!");

  const getCurrentUserFromFb = (userId: string) => {
    const path = USER_COLLECTION + "/"+ userId
    getSignleDocFromFirestore(path).then((docSnap) => {
      if(docSnap?.exists){
        try {
          const userData: User = docSnap?.data() as User;
          updateCurrentUser(userData)
        } catch (err) {
          console.log("Error", err)
        }
      }
    })
  }

  const updateCurrentUser = (newUser: User) => {
    setCurrentUser(newUser);
    localStorage.setItem(
      localStorageUserKey,
      JSON.stringify(newUser)
    );
  };

  const getCurrentUser = (): User | null => {

    const localStorageValue = localStorage.getItem(localStorageUserKey);
    if(localStorageValue){
      try {
        return (JSON.parse(localStorageValue)) as User
      } catch (error) {
        console.log("error while converting localstorage data to User") 
      }
    }
    
    return null
  };

  const logIn = (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password)  
  }

  const logOut = () => {
    localStorage.removeItem(localStorageUserKey);
    return signOut(auth) 
  }



  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if(authUser?.uid){
        getCurrentUserFromFb(authUser?.uid);
      }

    });

    return () => unsubscribe();
  }, []);

  const localStorageUserData = getCurrentUser()
  if(currentUser.id === "" && localStorageUserData !== null){
    console.log("Inside id condition");
    updateCurrentUser(localStorageUserData);
  }

  const values = {
    logIn,
    logOut,
    currentUser,
    updateCurrentUser,
    getCurrentUser
  }

  return (
    <GlobalContext.Provider value={values}>
      {children}
    </GlobalContext.Provider>
  );
};
