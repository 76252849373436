import TicketUpdate from "../classes/TicketUpdate";
import { formatTimestamp } from "../services/DateUtils";

interface TicketUpdatesProps {
  updates: TicketUpdate[];
}

function TicketUpdates(props: TicketUpdatesProps) {
  const { updates } = props;
  console.log("TicketUpdates: ", updates);

  return (
    <>
      <div className="updates-container">
        <h2 className="section-title mt-1">Latest Updates</h2>
        {updates.map((update, index) => (
          <div className="update-container">
            <div className="update-timestamp-container">
              <img src="/icons/schedule.svg" alt="" />
              <p className="timestamp">{formatTimestamp(update.created_at)}</p>
            </div>
            <div className="update-body">
              <h3 className="update-title">{update.title}</h3>
              <div className="author-container">
                {update.avatar ? (
                  <img className="author-avatar" src={update.avatar} alt="" />
                ) : (
                  <p className="default-avatar"></p>
                )}

                <p className="author-name">{update.updated_by}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default TicketUpdates;
