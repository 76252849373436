import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  setDoc,
  orderBy,
  query,
} from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";

const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINNG_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MESUREMENT_ID,
});

export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);

const ROOT_NODE = "Projects";

export const writeDataToFirestore = async (path: string, data: any) => {
  try {
    const docRef = collection(firestore, path);
    const docId = docRef.id;
    data["id"] = docId;

    return await addDoc(docRef, data);
  } catch (error) {
    console.log("Error while writing data to firestore: ", error);
  }
};

export const writeDataToFirestoreWithCustomID = async (
  path: string,
  id: string,
  data: any
) => {
  try {
    const docRef = doc(firestore, path, id);
    // const docId = docRef.id;
    data["id"] = id;

    return await setDoc(docRef, data);
  } catch (error) {
    console.log("Error while writing data to firestore: ", error);
  }
};

export const writeNewCustomer = async (id: string, data: any) => {
  try {
    return await writeDataToFirestoreWithCustomID("Users", id, data);
  } catch (error) {
    console.log("Error while writing data to firestore: ", error);
  }
};

export const writeNewTicketToFB = async (
  projectId: string,
  id: string,
  data: any
) => {
  try {
    const path = ROOT_NODE + "/" + projectId + "/Tickets/";
    data["id"] = id;

    return writeDataToFirestoreWithCustomID(path, id, data);
  } catch (error) {
    console.log("Error while writing ticket data to firestore: ", error);
  }
};

export const writeNewReleaseToFB = async (
  projectId: string,
  id: string,
  data: any
) => {
  try {
    const path = ROOT_NODE + "/" + projectId + "/Releases/";
    data["id"] = id;

    return writeDataToFirestoreWithCustomID(path, id, data);
  } catch (error) {
    console.log("Error while writing ticket data to firestore: ", error);
  }
};

export const getDocsFromFirestore = async (path: string, data: any) => {
  try {
    const docsRef = collection(firestore, path);

    return await getDocs(docsRef);
  } catch (error) {
    console.log("Error while reading data from firestore: ", error);
  }
};

export const getDocsFromFirestoreWithSort = async (
  path: string,
  parm: string,
  order: "desc" | "asc"
) => {
  try {
    const docsRef = collection(firestore, path);
    const q = query(docsRef, orderBy(parm, order));

    return await getDocs(q);
  } catch (error) {
    // console.log("Error while reading data from firestore: ", error);
    throw error;
  }
};

export const getSignleDocFromFirestore = async (path: string) => {
  try {
    const docsRef = doc(firestore, path);

    return await getDoc(docsRef);
  } catch (error) {
    console.log("Error while reading signle doc from firestore: ", error);
  }
};

export const uploadTicketMedia = async (userId: string, mediaFiles: File[]) => {
  let downloadableUrls = [];

  // Upload files to Firebase Storage
  const uploadTasks = mediaFiles.map((file) => {
    const timestamp = Date.now();
    const storageRef = ref(
      storage,
      `tickets-media/${userId}/${timestamp}-${file.name}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);
    return uploadTask;
  });

  try {
    // Get download URLs and save to Firestore after all uploads are complete
    const snapshots = await Promise.all(uploadTasks);

    const urls = await Promise.all(
      snapshots.map((snapshot) => getDownloadURL(snapshot.ref))
    );

    downloadableUrls.push(...urls);

    // Return the downloadableUrls array
    return downloadableUrls;
  } catch (error) {
    console.error("Error uploading media:", error);
    // Handle the error, you might want to throw it or handle it in a specific way
  }
};

export const uploadReleaseMedia = async (
  userId: string,
  mediaFiles: File[]
) => {
  let downloadableUrls = [];

  // Upload files to Firebase Storage
  const uploadTasks = mediaFiles.map((file) => {
    const timestamp = Date.now();
    const storageRef = ref(
      storage,
      `release-media/${userId}/${timestamp}-${file.name}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);
    return uploadTask;
  });

  try {
    // Get download URLs and save to Firestore after all uploads are complete
    const snapshots = await Promise.all(uploadTasks);

    const urls = await Promise.all(
      snapshots.map((snapshot) => getDownloadURL(snapshot.ref))
    );

    downloadableUrls.push(...urls);

    // Return the downloadableUrls array
    return downloadableUrls;
  } catch (error) {
    console.error("Error uploading media:", error);
    // Handle the error, you might want to throw it or handle it in a specific way
  }
};

export default auth;
