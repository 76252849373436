import { useContext, useEffect, useState } from "react";
import Header from "./Header";
import Release from "../classes/Release";
import { getLatestReleases } from "../services/latest-releases";
import { formatTimestamp } from "../services/DateUtils";
import NavBar from "./NavBar";
import CustomSnackbar from "./CustomSnackbar";
import { snackBarConfigType } from "../classes/SnackBarConfig";
import { Link } from "react-router-dom";
import { GlobalContext } from "../contexts/GlobalContext";
import { LazyLoadImage } from "react-lazy-load-image-component";

const RELEASE_DESC_CHAR_LIMIT = 150;
const envOptions = [
  { platform: "iOS" },
  { platform: "android" },
  { platform: "admin/web" },
];

function Dashboard() {
  let [snackBarConfig, setSnackBarConfig] = useState<snackBarConfigType>({
    open: false,
    severity: "success",
    message: "",
  });

  const [isLoading, setIsLoading] = useState(true);
  const [userPermission, setUserPermission] = useState("");
  let [releases, setReleases] = useState<Release[]>([]);
  let [filteredReleases, setFilteredReleases] = useState<Release[]>([]);

  const { currentUser, getCurrentUser } = useContext(GlobalContext);

  const projectId = "allhorses-prod";
  // const projectId = "allhorses";

  useEffect(() => {
    const fetchData = async () => {
      setReleases([]);

      try {
        setUserPermission(currentUser.role);
        console.log("fetching latest release data from fb for: " + projectId);
        const releasesFromFB = await getLatestReleases(projectId);
        const data = releasesFromFB?.docs.map((doc) => doc.data() as Release);

        if (data) {
          console.log("latest release data form fb: ", data);
          setIsLoading(false);
          setReleases(data);
          envOptionClickHandler(0, data);
        }
      } catch (error) {
        console.log("Error fetching data:", error);
        setSnackBarConfig({
          open: true,
          severity: "error",
          message: "Error fetching data: " + error,
        });
      }
    };

    fetchData();
  }, []);

  let [selectedEnvTag, setSelectedEnvTag] = useState(0);
  let [viewFullDesc, setViewFullDesc] = useState("");

  const envOptionClickHandler = (
    envIndex: number,
    releaseDataArray?: Release[]
  ) => {
    const platforms = ["iOS", "android", "web"];
    setSelectedEnvTag(envIndex);

    let originalData: Release[] = [];

    if (envIndex >= 0 && envIndex < platforms.length) {
      const selectedPlatform = platforms[envIndex];
      console.log(`${selectedPlatform} selected`);

      if (releaseDataArray) {
        originalData = releaseDataArray;
      } else {
        originalData = releases;
      }

      const filteredReleases = originalData.filter(
        (release) => release.platform === selectedPlatform
      );

      console.log("filtered releases: ", filteredReleases);
      setFilteredReleases(filteredReleases);
      console.log("selected platform: ", selectedPlatform);
    }
  };

  const getReleaseDesc = (originalDesc: string) => {
    // let limtedDesc = "";
    if (originalDesc.length >= RELEASE_DESC_CHAR_LIMIT) {
      return originalDesc.slice(0, RELEASE_DESC_CHAR_LIMIT) + " ...";
    } else {
      return originalDesc;
    }

    // return limtedDesc ?? originalDesc;
  };

  const viewFullDescClickHandler = (index: number) => {
    setViewFullDesc(viewFullDesc + "" + index);
  };

  // Function to check if the URL is a video
  const isVideo = (url: string) => {
    const lowerCaseUrl = url.toLowerCase();
    return /\.(mp4|ogg|webm)(\?.*)?$/i.test(lowerCaseUrl);
  };

  return (
    <>
      <div className="mainFrame">
        <Header />
        <CustomSnackbar snackbarConfig={snackBarConfig} />

        <div className="card">
          <div className="title-contianer">
            <div className="card-title-contianer">
              <img src="/icons/env-tag-prod.svg" alt="" />
              <h1 className="card-title pb-1">Latest Release</h1>
            </div>
            {userPermission.toLowerCase() === "admin" ? (
              <Link className="button" to={"/" + projectId + "/new-release/"}>
                <img
                  className="button-icon"
                  src="/icons/add_button.svg"
                  alt=""
                />{" "}
                New Release
              </Link>
            ) : (
              <></>
            )}
          </div>

          <div className="env-container">
            {envOptions.map((option, index) => (
              <div
                key={index}
                className={
                  index === selectedEnvTag
                    ? "option-tag selected-tag"
                    : "option-tag"
                }
                onClick={() => envOptionClickHandler(index)}
              >
                {option.platform}
              </div>
            ))}

            <div className="new-button"></div>
          </div>

          {isLoading ? (
            <img
              className="loading-anim"
              src="/icons/loading-anim.gif"
              alt=""
            />
          ) : (
            <></>
          )}

          <div className="secondary-card-container">
            {filteredReleases.map((release, index) => (
              <div key={index} className="secondary-card">
                <div className="flexRow article-header">
                  <div className="flexRow article-title-container">
                    <h1 className="article-title">{release.title}</h1>
                    {release.env === "prod" ? (
                      <p className="article-env-tag">{release.env}</p>
                    ) : (
                      <p className="env-test article-env-tag">{release.env}</p>
                    )}
                  </div>
                  <img
                    className="env-icon"
                    src={"/icons/env-tag-" + release.env + ".svg"}
                    alt="env-icon"
                  />
                </div>

                <div className="article-body">
                  <div className="article-section">
                    <h3 className="section-title">Released at:</h3>
                    <p className="section-body">
                      {formatTimestamp(release.released_at)}
                    </p>
                  </div>
                  <div className="article-section">
                    <h3 className="section-title">Details:</h3>
                    <div className="release-desc-container">
                      {viewFullDesc.includes(index.toString()) ? (
                        <>
                          <p className="section-body">{release.details}</p>
                          <div className="article-section">
                            <h3 className="section-title">
                              Screenshots/videos:
                            </h3>
                            <div className="release-desc-container">
                              {release?.media?.length > 0 && (
                                <div
                                  className={
                                    release.media.length > 1
                                      ? "media-container scroll-x"
                                      : "media-container"
                                  }
                                >
                                  {release?.media?.map((mediaUrl, index) => (
                                    <div
                                      key={index}
                                      className="media-container"
                                    >
                                      {isVideo(mediaUrl) ? (
                                        <video
                                          className="ticket-media-video"
                                          controls
                                          autoPlay
                                          loop
                                          muted
                                        >
                                          <source
                                            src={mediaUrl}
                                            type="video/mp4"
                                          />
                                          Your browser does not support the
                                          video tag.
                                        </video>
                                      ) : (
                                        <>
                                          <LazyLoadImage
                                            className="ticket-media"
                                            alt={""}
                                            effect="blur"
                                            wrapperProps={{
                                              // If you need to, you can tweak the effect transition using the wrapper style.
                                              style: {
                                                transitionDelay: "0.5s",
                                              },
                                            }}
                                            src={mediaUrl} // use normal <img> attributes as props
                                          />
                                        </>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <p className="section-body">
                          {getReleaseDesc(release.details)}
                        </p>
                      )}
                      {!viewFullDesc.includes(index.toString()) &&
                      release.details.length >= RELEASE_DESC_CHAR_LIMIT ? (
                        <p
                          className="flexCol more-info"
                          onClick={() => viewFullDescClickHandler(index)}
                        >
                          {viewFullDesc.includes(index.toString())
                            ? ""
                            : "view full details ..."}
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  <div className="flexRow button-containers article-section">
                    {release.env === "prod" ? (
                      <a
                        href={release.release_note_url}
                        target="_blank"
                        rel="noreferrer"
                        className="button"
                      >
                        <img
                          className="button-icon"
                          src="/icons/description.svg"
                          alt=""
                        />{" "}
                        View Release Note
                      </a>
                    ) : (
                      <></>
                    )}
                    <a
                      href={release.live_link}
                      target="_blank"
                      rel="noreferrer"
                      className={
                        release.env === "prod" ? "button" : "button width100"
                      }
                    >
                      <img
                        className="button-icon"
                        src="/icons/exit-to-app.svg"
                        alt=""
                      />{" "}
                      View App
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <NavBar></NavBar>
    </>
  );
}

export default Dashboard;
